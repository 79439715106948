import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({
  title,
  description,
  keywords,
  image,
  url,
  type = 'website',
}) => {
  const siteTitle = 'Qualmission';
  const defaultDescription = 'Premier consulting services in USA providing quality services and resources exclusively tailored to accelerate business growth.';
  const defaultKeywords = 'consulting, USA consulting, business growth, technology consulting, innovation, strategy consulting, Qualmission';
  const defaultImage = '/logo_n.png';
  const siteUrl = 'https://qualmission.com';
  const phoneNumber = '+1 (475) 615-4537';  

  const seo = {
    title: title ? `${title} | ${siteTitle}` : `${siteTitle} - Premier Consulting Services in USA`,
    description: description || defaultDescription,
    keywords: keywords || defaultKeywords,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${url || ''}`,
    type,
  };

  const schemaOrgWebPage = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: siteTitle,
    url: siteUrl,
    logo: `${siteUrl}/logo_n.png`,
    description: seo.description,
    telephone: phoneNumber,
    contactPoint: {
      '@type': 'ContactPoint',
      telephone: phoneNumber,
      contactType: 'customer service',
      areaServed: 'US',
      availableLanguage: ['English']
    },
    address: {
      '@type': 'PostalAddress',
      addressCountry: 'USA',
      addressRegion: 'CA',
      addressLocality: 'Fremont'
    },
    sameAs: [
      'https://www.linkedin.com/company/qualmission',
      'https://twitter.com/qualmission',
    ],
  };

  return (
    <Helmet>
      {/* General tags */}
      <title>{seo.title}</title>
      <meta name="description" content={seo.description} />
      <meta name="keywords" content={seo.keywords} />
      <meta name="image" content={seo.image} />
      <link rel="canonical" href={seo.url} />

      {/* Contact Information */}
      <meta name="contact" content={phoneNumber} />

      {/* OpenGraph tags */}
      <meta property="og:url" content={seo.url} />
      <meta property="og:type" content={seo.type} />
      <meta property="og:title" content={seo.title} />
      <meta property="og:description" content={seo.description} />
      <meta property="og:image" content={seo.image} />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:phone_number" content={phoneNumber} />

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={seo.title} />
      <meta name="twitter:description" content={seo.description} />
      <meta name="twitter:image" content={seo.image} />

      {/* Schema.org tags */}
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
    </Helmet>
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  keywords: PropTypes.string,
  image: PropTypes.string,
  url: PropTypes.string,
  type: PropTypes.string,
};

export default SEO;
